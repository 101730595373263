<template>
  <div class="container">

    <div :data-src="mediaBase + '/images/help_background.svg'" uk-img
      class="uk-height-large uk-background-norepeat  uk-background-center-center uk-background-image@s uk-section uk-padding-remove-top uk-flex uk-flex-middle">
      <div class="uk-width-1-1 uk-container">
        <div class="uk-container-xsmall uk-margin-auto uk-text-center">
          <h3>Sıkça Sorulan sorular</h3>
          <div class="uk-margin">
            <div class="uk-inline uk-width-1-1">
              <span class="uk-form-icon"><i class="uil-search"></i></span>
              <input class="uk-input  uk-form-large" type="text" v-model="filter.search" placeholder="Konu ara...">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-container-xsmall uk-margin-auto">
      <template v-if="formattedItem">
        <template v-for="category in formattedItem">
          <h4> {{ category.title }} </h4>
          <article class="uk-card-default p-4 rounded mb-4">
            <ul class="uk-list-divider uk-list-large uk-accordion" uk-accordion>
              <template v-for="faq in category.faqs">
                <li v-if="faq?.description?.description">
                  <a class="uk-accordion-title" href="#">{{ faq.description.title }}</a>
                  <div class="uk-accordion-content">
                    <div v-html="faq.description.description"></div>
                    <!-- <vue-editor v-model="htmlForEditor"></vue-editor> -->
                    <!-- <p  > {{faq.description.description}}</p> -->
                  </div>
                </li>
              </template>
            </ul>
          </article>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import DashboardLayout from "@/view/layouts/DashboardLayout";
import store from "@/core/services";

import module, {
  BASE_URL,
  MODULE_NAME,
  ERROR,
  ITEMS,
  GET_LIST,
  LOADING
} from "@/core/services/store/faq.module";

export default {
  name: "FAQ",
  components: {
    DashboardLayout
  },
  data() {
    return {
      filter: {
        search: null,
      },
    }
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(MODULE_NAME, module);
  },
  computed: {
    items: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEMS];
      },
      set(value) { },
    },
    assignment_loading: {
      get() {
        return store.getters[MODULE_NAME + "/" + LOADING];
      },
      set(value) { },
    },
    error: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
    formattedItem() {
      let results = [];
      if (this.items.length > 0) {
        this.items?.forEach((currentItem) => {
          results.push({
            id: currentItem.id,
            title: currentItem.description.title,
            faqs: currentItem.faqs
          });
        });
      }
      return results;
    },
  },
  watch: {
    "filter.search": function (val) {
      this.debouncedFilterSearch();
    },
  },
  methods: {
    getFaqResult() {
      let self = this;
      this.$store.dispatch(MODULE_NAME + "/" + GET_LIST, {
        url: BASE_URL,
        filters: {
          search: self.filter.search,
        },
      })
    },
  },
  created: function () {
    this.debouncedFilterSearch = _.debounce(this.getFaqResult, 500);
  },
  mounted() {
    if (document.getElementById("innerdiv") != null) {

      document.getElementById("innerdiv").
        innerHTML += "<h3>Hello geeks</h3>";
    }
    this.getFaqResult();
  },
}
</script>

<style scoped></style>